<template>
  <div style="margin-top: 80px;" class="login-section">
    <div class="image-layer p-4">

        <div style="background-color: rgb(246, 61, 20); height:100%;border-radius: 20px;padding: 40px;" >  
         
            <h3 style="color: white;font-weight: 700;" >LIGHTSPEED</h3>
            <p style="color: white;" >Distributor | Franchises</p>
            <h1 style="color: white;font-weight: 700; margin-top: 30px;font-size: 300%;" >START YOUR JOURNEY WiTH US</h1>
             <h3 style="color: white;margin-top: 30px;" >Discover the world best manufacturers and distributors</h3>


             <div style="background-color: #ff9f9f;height: 200px;margin-top: 40px;border-radius: 50px;padding: 30px;" >
            <p>"Supplied directly pleasant we ignorant ecstatic of jointure so if. These spoke house of we. Ask put yet excuse person see change. Do inhabiting no stimulated unpleasing of admiration he. Enquire explain another he in brandon enjoyed be service."</p>
            <h4 class="mt-4 text-gray">....Jon Brownly</h4>
        </div>

        </div>


    </div>
    <div class="outer-box">
      <!-- Login Form -->
      <div class="login-form default-form">
        <div class="form-inner">
          <h3 class="mb-0">JOIN FREE</h3>

          <h5 class="mt-2" >Join free to Get The Best Deals, Exclusive
Offers with GetDistributors</h5>

<div class="mt-3" >  

<span style="padding: 5px 10px; min-width: 100px;background-color: #ff9f9f;border-radius: 25px;" >
  <input  style="height: 20px;width: 20px;background-color: rgb(246, 61, 20);border-radius: 25px;"
                  class="form-check-input"
                  type="checkbox"
                  v-model="visitor"
                  @input="distributor = visitor"
 
               
                /> Looking for a Disributor</span> 

                {{ role }}

            
                

<span style="padding: 5px 10px; min-width: 100px;background-color: #ff9f9f;border-radius: 25px;margin-left: 10px;" >
  <input  style="height: 20px;width: 20px;background-color: rgb(246, 61, 20);border-radius: 25px;"
                  class="form-check-input"
                  type="checkbox"
                  v-model="distributor"
                  @input="visitor=distributor"
                  


                /> As a Disributor</span> 

            



</div>
          <!--Login Form-->
          <form class="mt-3" id="login-form" @submit.prevent="login">
            <div class="form-group">
          
              <input type="number" v-model="phone" placeholder="Phone number" />

              <p v-if="emailerror != ''" class="mt-1 text-danger">
                {{ emailerror }}
              </p>
            </div>
            <div class="form-group">
          
          <input type="text" v-model="email" placeholder="Email" />

          <p v-if="emailerror != ''" class="mt-1 text-danger">
            {{ emailerror }}
          </p>
        </div>
        <div class="form-group">
          
          <input type="text" v-model="name" placeholder="Your name" />

          <p v-if="emailerror != ''" class="mt-1 text-danger">
            {{ emailerror }}
          </p>
        </div>
        <div class="form-group">
          
          <input type="text" v-model="company_name" placeholder="Comapny name" />

          <p v-if="emailerror != ''" class="mt-1 text-danger">
            {{ emailerror }}
          </p>
        </div>
        <div class="form-group">
          
          <input type="text" v-model="postal_code" placeholder="Postal code" />

          <p v-if="emailerror != ''" class="mt-1 text-danger">
            {{ emailerror }}
          </p>
        </div>
        <div class="form-group">
          
          <input type="text" v-model="product_name" placeholder="Product name" />

          <p v-if="emailerror != ''" class="mt-1 text-danger">
            {{ emailerror }}
          </p>
        </div>
          
        <div class="form-group">
          
          <input type="password" v-model="password" placeholder="Password" />

          <p v-if="emailerror != ''" class="mt-1 text-danger">
            {{ emailerror }}
          </p>
        </div>
          
          

        

            <div class="form-group">
              <div class="field-outer">
                <div class="input-group checkboxes square">
                  <input
                    type="checkbox"
                    name="remember-me"
                    value=""
                    id="remember"
                  />
                  <label for="remember" class="remember"
                    ><span class="custom-checkbox"></span> Remember me</label
                  >
                </div>
                <!-- <a href="#" class="pwd">Forgot password?</a> -->
              </div>
            </div>

            <div class="form-group w-50 ">
              <button @click="join" class="theme-btn btn-style-one" type="submit">
                SUBMIT  <i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>

            <!-- <div class="text">
              Don't have an account?
              <a class="text-success" href="#">SIGNUP</a>
            </div> -->
          </form>

          <div class="bottom-box">
            <div id="loader-small">
              <span class="loading__dot_small"></span>
              <span class="loading__dot_small"></span>
              <span class="loading__dot_small"></span>
            </div>

            <!-- <div class="divider"><span>or</span></div>
                <div class="btn-box row">
                  <div class="col-lg-6 col-md-12">
                    <a href="#" class="theme-btn social-btn-two facebook-btn"><i class="fab fa-facebook-f"></i> Log In via Facebook</a>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <a href="#" class="theme-btn social-btn-two google-btn"><i class="fab fa-google"></i> Log In via Gmail</a>
                  </div>
                </div> -->
          </div>

          <div
            id="otpbox"
            style="border-radius: 10px"
            class="p-3 border text-center"
          >
            <h5 class="text-success">
              We send you a OTP to your mail ! Check your mail
            </h5>

            <form @submit.prevent="emailverification">
              <div class="row mt-3">
                <div class="col-9">
                  <div class="form-group">
                    <input
                      id="password-field"
                      v-model="otp"
                      type="password"
                      placeholder="Enter OTP"
                    />
                    <p v-if="otperror != ''" class="mt-1 text-danger">
                      {{ otperror }}
                    </p>
                  </div>
                </div>
                <div class="col-3 p-1">
                  <button class="btn btn-lg btn-primary">
                    <i class="las la-angle-double-right"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!--End Login Form -->
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";
import vcookie from "vue-cookie";

export default {
  name: "LoginView",

  data() {
    return {
      user: {},
      emailerror: "",
      passworderror: "",

      email: "",
      password: "",
      otperror: "",

      visitor:true,
      distributor:false,
      role:"visitor",
      


   

    };
  },
  methods: {
  join(){

     if(this.visitor){
       this.role="visitor"
     }else{
       this.role="distributor"
     }

      axios.post("auth/register", {
      name: this.name,
      email: this.email,
      phone: this.phone,
      password: this.password,
      role: this.distributor,
      company_name: this.company_name,
      postal_code: this.postal_code,
      product_name: this.product_name,
    




  },).then((res) => {
    if(res){
      this.$emit("get_message", res.data.message);
    }

    
  }).catch((error) => {
    console.log(error.response);
  })

},
    login() {
      var loader = document.getElementById("loader-small");
      loader.style.display = "block";

      if (this.email == "") {
        this.emailerror = "Enter email Address";
      } else if (!this.email.includes("@")) {
        this.emailerror = "Enter valid email Address";
      } else if (this.password == "") {
        this.passworderror = "Enter password";
      } else if (this.password.length < 8) {
        this.passworderror = "Enter 8 digit password";
      } else {
        vcookie.set("admin", "admin");

        if (
          this.email == "lightspeed@qq.com" &&
          this.password == "lightspeed1234"
        ) {
          this.$router.push({ name: "admin.dashboard" });
        }
      }
    },
    resendemail() {
      if (this.email != "") {
        axios
          .post("access/verification_code/", { email: this.email })
          .then((res) => {
            if (res.status == 200) {
              var loginForm = document.getElementById("login-form");
              var loader = document.getElementById("loader-small");

              var otpbox = document.getElementById("otpbox");

              otpbox.style.display = "block";
              loginForm.style.display = "none";
              loader.style.display = "none";
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },

    emailverification() {
      var loader = document.getElementById("loader-small");
      loader.style.display = "block";

      if (this.otp == null) {
        this.otperror = "Enter OTP";
      } else if (this.otp.length < 6) {
        this.otperror = "Enter 6 digit OTP";
      } else {
        axios
          .post("/access/verification/", {
            email: this.email,
            verification_code: this.otp,
          })
          .then((res) => {
            if (res.status == 202) {
              alert("Verification Successfully");
              loader.style.display = "none";

              location.reload();
            }
          })
          .catch((error) => {
            var loader = document.getElementById("loader-small");
            loader.style.display = "none";
            alert("Your otp expired or invalid");
            console.log(error.response);
          });
      }
    },
  },
};
</script>
  
  <style>
</style>