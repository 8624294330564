<template>
  <section class="job-detail-section">
    <div style="margin-top: 50px" class="job-detail-outer">
      <div class="auto-container">
        <div class="row">
          <p>Distributors > Global > WheelsHdpe Sheet</p>

          <div class="row">
            <div class="col-lg-4 p-3">
              <div style="border-radius: 20px" class="p-3 text-center border">
                <img
                  class=""
                  style="height: 300px; width: 100%"
                  :src="
                      fileurl+'image/product/' +
                      product.probduct_thum
                    "
                />
              </div>
            </div>

            <div class="col-lg-8 p-3 text-bold">
              <h3>{{ product.title }}</h3>

              <div class="row mt-3 ms-1">
                <div
                  style="border-radius: 20px 0px 0px 0px"
                  class="col-lg-4 m-0 p-0 border"
                >
                  <div
                    style="
                      border-radius: 20px 0px 0px 0px;
                      width: 100%;
                      padding: 20px;
                      background-color: antiquewhite;
                    "
                  >
                    <h6>LAST UPDATED ON</h6>

                    <p class="mt-1">22-3-2014</p>
                  </div>
                </div>
                <div class="col-lg-4 m-0 p-0 border">
                  <div
                    style="
                      width: 100%;
                      padding: 20px;
                      background-color: antiquewhite;
                    "
                  >
                    <h6>PRODUCT ADDED ON</h6>

                    <p class="mt-1">22-3-2014</p>
                  </div>
                </div>
                <div
                  style="border-radius: 0px 20px 0px 0px"
                  class="col-lg-4 m-0 p-0 border"
                >
                  <div
                    style="
                      border-radius: 0px 20px 0px 0px;
                      width: 100%;
                      padding: 20px;
                      background-color: antiquewhite;
                    "
                  >
                    <h6>WE ARE</h6>

                    <p class="mt-1">looking for distributors</p>
                  </div>
                </div>
              </div>

              <div class="row ms-1">
                <div
                  style="border-radius: 0px 0px 0px 20px"
                  class="col-lg-4 m-0 p-0 border"
                >
                  <div
                    style="
                      border-radius: 0px 0px 0px 20px;
                      width: 100%;
                      padding: 20px;
                      background-color: antiquewhite;
                    "
                  >
                    <h6>SPACE REQUIRED</h6>

                    <p class="mt-1">{{ product.space_required }}</p>
                  </div>
                </div>
                <div class="col-lg-4 m-0 p-0 border">
                  <div
                    style="
                      width: 100%;
                      padding: 20px;
                      background-color: antiquewhite;
                    "
                  >
                    <h6>INVESTMENT REQUIRED</h6>

                    <p class="mt-1">{{ product.investment_required }}</p>
                  </div>
                </div>
                <div
                  style="border-radius: 0px 0px 20px 0px"
                  class="col-lg-4 m-0 p-0 border"
                >
                  <div
                    style="
                      border-radius: 0px 0px 20px 0px;
                      width: 100%;
                      padding: 20px;
                      background-color: antiquewhite;
                    "
                  >
                    <h6>Brand</h6>

                    <p class="mt-1">""</p>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <a
                  href="#"
                  style="border-radius: 20px"
                  class="theme-button px-3 py-2 mt-3"
                  >Apply For Distributor</a
                >
              </div>
            </div>
          </div>

          <div> 

            <h3>Product Description</h3>

            <p class="mt-1" >{{ product.details }}</p>



          </div>
        </div>
      </div>
    </div>
  </section>
</template>
  
  <script>
import axios from "axios";
import Constant from "@/other/Constant"

export default {
  data() {
    return {
      product: "",
      fileurl: Constant.filebaseurl,

    };
  },

  methods: {
    getProduct(id) {
      axios
        .get("/public/products/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.product = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },

  mounted() {
    var id = this.$route.query.id;

    if (id != null) {
      this.getProduct(id);
      window.scrollTo(0, 0);
    }
  },
};
</script>
  
  