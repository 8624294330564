<template>

    <div class="page-wrapper auth">
    

<div v-if="msg" id="message_popup" class="alert alert-success">
    <div class="text-center">
   
      <i style="display: block;color:rgb(240, 144, 19);font-size:200%" class="fa-solid fa-check"></i>
    <h4>{{ msg }}</h4>

    </div>
  
  </div>

    <auth-header-view></auth-header-view>
    <router-view @get_message="message_show" ></router-view>
    <auth-footer-view></auth-footer-view>

    
    
    </div>
        
      
    </template>
    
    <script>
import AuthHeaderView from '@/components/auth/AuthHeaderView.vue'
import AuthFooterView from '@/components/auth/AuthFooterView.vue'
 
    export default {
        name:'AuthMasterView',
        components:{AuthHeaderView,AuthFooterView},
   
        data() {
    return {
      msg: "",
      delete_msg: "",
      is_delete:false,
      dataToPass: false,
    };
    
  },

  provide() {
    return {
      delete_status: "",
    };
  },
  methods:{
    message_show(message) {
  this.msg = message;

     
 
      this.message_hide()
 

},
delete_show(message) {

 
this.delete_msg = message;



this.delete_popup_show();

if ( document.getElementById('delete_popup') ){
  const box = document.getElementById("delete_popup");
box.style.display = "block";


}







},
delete_popup_show(){


},

message_hide() {

   
    

setTimeout(() => {

document.getElementById("message_popup").style.display = "none";

}, 2000);


if ( document.getElementById('message_popup') ){
const box = document.getElementById("message_popup");
box.style.display = "block";


}




},
  }
}



</script>

<style>

</style>