<template>
    <section class="job-detail-section">
      <div style="margin-top: 50px" class="job-detail-outer">
        <div class="auto-container">
          <div class="row">
            <p>Distributors > Global > WheelsHdpe Sheet</p>
  

           
  
            <div class="mt-4" > 
  
              <h3>All Brands</h3>
              <div style="z-index: 9" class="row wow fadeInUp ">
          <div @click="showbrand(brand.id)" v-for="brand in brands" :key="brand" class="col-md-3 ">
            <div style="border-radius: 30px;" class="custom-card p-3 mt-2">
              <div class="row">
                <div class="col-md-4 ">
                  <img class="border"
                    style="height: 60px; width: 60px;border-radius: 50%;"
                    :src="
                      fileurl+'image/brnad/' +
                      brand.icon
                    "
                  />
                </div>

                <div  v-if="brand.category" class="col-md-8 mt-sm-2">
                         <span style="font-size: 12px;background-color: #e8ffff ;padding: 2px 20px;border-radius: 15px;color: #53cecb;" class="">
                          {{brand.category.name}}
                         </span>
             
                  <h6
                    class="font110 mt-2"
                    style="text-align: left; font-weight: 500"
                  >
                  {{ brand.name}}
                  </h6>

                  
              
                </div>
                
                <div class="row">
                    <div class="col-12">
                      <!-- <span class="icon flaticon-briefcase"></span> -->

                      <span class="small-font ">
                        Investment price range</span
                      >
                    </div>

                    <p class="text-black">1.00Lac-4.00Lac</p>

                  
                  </div>



              </div>
            </div>
          </div>

        </div> 
  
            
  
  
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
    
    <script>
  import axios from "axios";
  import Constant from "@/other/Constant"
  export default {
    data() {
      return {
        product: "",
        distributors:[],
        fileurl: Constant.filebaseurl,
        brands:[],

      
      };
    },
  
    methods: {
      showdistributor(id) {
      if (id) {
        this.$router.push({ name: "distributor-single", query: { id: id } });
      }
    },
      getProduct(id) {
        axios
          .get("/public/products/" + id)
          .then((response) => {
            if (response.status == 200) {
              this.product = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      },
      getDistributors() {
      axios
        .get("/public/distributors")
        .then((res) => {
          if (res.status == 200) {
            this.distributors = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getbrand() {
      axios
        .get("/public/brands")
        .then((res) => {
          this.brands = res.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    },
  
    mounted() {
      // var id = this.$route.query.id;
      this.getbrand();
      this.getDistributors()
    },
  };
  </script>
    
    