<template>
    <section @submit.prevent="postjob" class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3>Edit Product</h3>
        </div>
  
        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box p-3">
                <div class="widget-content">
                  <form class="default-form">
                    <div class="row">
                      <!-- Input -->
  
                      <div class="form-group col-lg-12 col-md-12">
                        <label>Product Title</label>
                        <input
                          type="text"
                          v-model="title"
                          placeholder="Product Title"
                          required
                        />
                      </div>
  
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Slug</label>
                        <input type="text" v-model="slug" placeholder="Slug" />
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Investment Required</label>
                        <input
                          type="text"
                          v-model="investment_required"
                          placeholder="Investment Required"
                        />
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>GST NO.</label>
                        <input
                          type="text"
                          v-model="gst_no"
                          placeholder="GST NO."
                        />
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Established</label>
                        <input
                          type="text"
                          v-model="established"
                          placeholder="GST NO."
                        />
                      </div>
  
                      <div class="form-group col-lg-6 col-md-12">
                        <label>SPACE REQUIRED</label>
                        <input
                          type="text"
                          v-model="space_required"
                          placeholder="SPACE REQUIRED"
                        />
                      </div>
  
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Brand</label>
                        <select v-model="brand_id" required>
                          <option disabled value="null">Select a brand</option>
  
                          <option v-for="brand in brands" :key="brand" :value="brand.id">{{brand.name}}</option>
                         
                        </select>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Category</label>
                        <select v-model="category_id" required>
                          <option disabled value="null">Select a category</option>
                          <option v-for="category in categories" :key="category" :value="category.id">{{ category.name }}</option>
         
                        </select>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Distributor</label>
                        <select v-model="distributor_id" required>
                          <option disabled value="null">Select a distributor</option>
  
                          <option v-for="distributor in distributors" :key="distributor" :value="distributor.id">{{distributor.name}}</option>
                         
                        </select>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Number of distributor</label>
                        <input
                          type="text"
                          v-model="no_of_detributor"
                          placeholder="Number of distributor"
                        />
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Distributor level</label>
                        <input
                          type="text"
                          v-model="distributorship_Level"
                          placeholder="Number of distributor"
                        />
                      </div>
  
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Country</label>
                        <input
                          type="text"
                          v-model="country"
                          placeholder="Country"
                        />
                      </div>
  
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Status</label>
                        <select v-model="status" required>
                          <option disabled value="null">Select a category</option>
  
                          <option value="1">Enabled</option>
                          <option value="0">Disabled</option>
                        </select>
                      </div>
  
                      <div class="form-group col-lg-12 col-md-12">
                        <label>Description</label>
                        <textarea
                          v-model="details"
                          placeholder="Description"
                          required
                        ></textarea>
                      </div>
  
                      <!-- About Company -->
  
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Thumbnail</label>
                        <input
                          class="form-control"
                          type="file"
                          @change="handleThumUpload"
                          placeholder="Thumbnail"
                          required
                        />
  
                        <div class="mt-2">
                          <span
                            class="mt-2"
                            v-if="thumurl"
                            style="position: relative"
                          >
                            <img
                              :src="thumurl"
                              class="me-3"
                              style="
                                height: 80px;
                                width: 80px;
                                border-radius: 2px;
                              "
                            />
                            <i
                              style="
                                position: absolute;
                                right: 10px;
                                background-color: rgba(16, 107, 157, 0.933);
                                padding: 2px;
                                color: #ffffff;
                                border-radius: 2px;
                              "
                              @click="removethum()"
                              class="fa-solid fa-x"
                            ></i>
                          </span>
                        </div>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Product iamges</label>
                        <input
                          class="form-control"
                          type="file"
                          @change="handleFileUpload"
                          multiple
                          required
                        />
  
                        <div v-if="imageUrls.length > 0" class="p-2 mt-2">
                          <span
                            style="position: relative"
                            v-for="(image, index) in imageUrls"
                            :key="image"
                          >
                            <img
                              :src="image"
                              class="me-3"
                              style="
                                height: 80px;
                                width: 80px;
                                border-radius: 2px;
                              "
                            />
                            <i
                              style="
                                position: absolute;
                                right: 10px;
                                background-color: rgba(16, 107, 157, 0.933);
                                padding: 2px;
                                color: #ffffff;
                                border-radius: 2px;
                              "
                              @click="removeImage(index)"
                              class="fa-solid fa-x"
                            ></i>
                          </span>
                        </div>
                      </div>
  
                      <!-- Input -->
                      <div class="form-group col-lg-12 col-md-12 text-right">
                        <button
                          @click.prevent="addProduct"
                          class="theme-btn btn-style-one"
                        >
                          Add Product
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import axios from "axios";
  
  
  // import moment from 'moment';
  
  export default {
    data() {
      return {
        files: [],
        imageUrls: [],
        categories: [],
        distributors: [],
        brands:[],
        category_id:null,
        brand_id:null,
        distributor_id:null,
        thumurl: "",
        file: "",
      };
    },
    methods: {
      addProduct() {
      
        var formData = new FormData();
        formData.append("title", this.title);
        formData.append("slug", this.slug);
        formData.append("investment_required", this.investment_required);
        formData.append("gst_no", this.gst_no);
        formData.append("brand_id", this.brand_id);
        formData.append("category_id", this.category_id);
        formData.append("distributor_id", this.distributor_id);
        formData.append("established", this.established);
        formData.append("space_required", this.space_required);
        formData.append("no_of_detributor", this.no_of_detributor);
        formData.append("distributorship_Level", this.distributorship_Level);
        formData.append("country", this.country);
        formData.append("probduct_thum", this.probduct_thum);
        formData.append("details", this.details);
        formData.append("status", this.status);
        formData.append("file", this.file);
  
        for (let i = 0; i < this.files.length; i++) {
          formData.append("files[]", this.files[i]);
        }
  
        axios
          .post("/admin/products", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status == 201) {
              this.$router.push({ name: "admin.product" });
              this.$emit("get_message", res.data.message);
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      },
  
      handleFileUpload(event) {
        this.files = event.target.files;
        if (this.files) {
          for (const element of this.files) {
            this.imageUrls.push(URL.createObjectURL(element));
          }
        }
      },
      handleThumUpload(event) {
        this.file = event.target.files[0];
        if (this.file) {
          this.thumurl = URL.createObjectURL(this.file);
        }
      },
      removeImage(index) {
        this.imageUrls.splice(index, 1);
      },
      removethum() {
        this.file = null;
        this.thumurl = null;
      },
      getCategory() {
        axios
          .get("/admin/categories")
          .then((res) => {
            this.categories = res.data.data;
          })
          .catch((error) => {
            console.log(error.response);
          });
      },
      getBrand() {
        axios
          .get("/admin/brands")
          .then((res) => {
            this.brands = res.data.data;
          })
          .catch((error) => {
            console.log(error.response);
          });
      },
      getGetDistributor() {
        axios
          .get("/admin/distributors")
          .then((res) => {
            this.distributors = res.data.data;
          })
          .catch((error) => {
            console.log(error.response);
          });
      },
    },
    mounted: function () {
      this.getCategory();
      this.getBrand();
      this.getGetDistributor();
    },
  };
  </script>
  
  
  