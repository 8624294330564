import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/pages/HomeView'
import LoginView from '@/views/pages/LoginView'
import JoinView from '@/views/pages/JoinView'

import ContactView from '@/views/pages/ContactView'
import NeedHelpView from '@/views/pages/NeedHelpView'
import HowItWorks from '@/views/pages/HowItWorks'

import RegisterView from '@/views/pages/RegisterView'

import DistributorSingleView from '@/views/pages/DistributorSingleView'
import AllDistributorView from '@/views/pages/AllDistributorView'
import AllBrandView from '@/views/pages/AllBrandView'
import TermsView from '@/views/pages/TermsView'
import PrivacyView from '@/views/pages/PrivacyView'

import ProductSingleView from '@/views/pages/ProductSingleView'
import CategoryProductView from '@/views/pages/CategoryProductView'
import CategoryPublicView from '@/views/pages/CategoryView.vue'
import SearchView from '@/views/pages/SearchView.vue'

import DashboardAdminView from '@/views/dashboard/DashboardAdminView'
import DistributorView from '@/views/dashboard/DistributorView'
import AddDistributorView from '@/views/dashboard/AddDistributorView'
import CategoryView from '@/views/dashboard/CategoryView'
import AddCategoryView from '@/views/dashboard/AddCategoryView'
import BrandView from '@/views/dashboard/BrandView'
import AddBrandView from '@/views/dashboard/AddBrandView'
import ProductView from '@/views/dashboard/ProductView'
import AddProductView from '@/views/dashboard/AddProductView'
import DashboardView from '@/views/dashboard/DashboardView'
import UserView from '@/views/dashboard/UserView'
import EditCategoryView from '@/views/dashboard/EditCategoryView'
import EditBrandView from '@/views/dashboard/EditBrandView'
import EditProductView from '@/views/dashboard/EditProductView'



import vcookie from 'vue-cookie'



const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  {
    path: '/login',
    name: 'login',
    meta: { layout: "auth" },
    component: LoginView
  },
  {
    path: '/join',
    name: 'join',
    meta: { layout: "auth" },
    component: JoinView
  },
  {
    path: '/register',
    name: 'register',
    meta: { layout: "auth" },
    component: RegisterView
  },



  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },

  {
    path: '/needhelp',
    name: 'needhelp',
    component: NeedHelpView
  },

  {
    path: '/howitworks',
    name: 'howitworks',
    component: HowItWorks
  },




  {
    path: '/terms',
    name: 'terms',
    component: TermsView
  },

  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyView
  },

  {
    path: '/distributor-single',

    name: 'distributor-single',
    component: DistributorSingleView
  },
  {
    path: '/all-distributor',

    name: 'all-distributor',
    component: AllDistributorView
  },
  {
    path: '/all-brand',

    name: 'all-brand',
    component: AllBrandView
  },
  {
    path: '/product-single',

    name: 'product-single',
    component: ProductSingleView
  },
  {
    path: '/category-product',

    name: 'category-product',
    component: CategoryProductView
  },
  {
    path: '/category-public',

    name: 'category-public',
    component: CategoryPublicView
  },
  {
    path: '/search',

    name: 'search',
    component: SearchView
  },



  //  dashboard Admin section start
  {
    path: '/admin-dashboard',
    name: 'admin-dashboard',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: DashboardAdminView
  },


  {
    path: '/admin-distributor',
    name: 'admin.distributor',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: DistributorView
  },
  {
    path: '/admin-add-distributor',
    name: 'admin.add.distributor',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AddDistributorView
  },

  {
    path: '/admin-category',
    name: 'admin.category',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: CategoryView
  },
  {
    path: '/admin-add-category',
    name: 'admin.category.add',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AddCategoryView
  },
  {
    path: '/admin-brand',
    name: 'admin.brand',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: BrandView
  },
  {
    path: '/admin-add-brand',
    name: 'admin.brand.add',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AddBrandView
  },
  {
    path: '/admin-product',
    name: 'admin.product',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: ProductView
  },
  {
    path: '/admin-add-product',
    name: 'admin.product.add',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AddProductView
  },
  {
    path: '/admin-dashboard',
    name: 'admin.dashboard',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: DashboardView
  },
  {
    path: '/admin-add-distributor',
    name: 'admin.distributor.add',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: AddDistributorView
  },
  
  {
    path: '/admin-user',
    name: 'admin.user',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: UserView
  },
  
  {
    path: '/admin-edit-category',
    name: 'admin.category.edit',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: EditCategoryView
  },
  
  {
    path: '/admin-edit-brand',
    name: 'admin.brand.edit',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: EditBrandView
  },
  
  {
    path: '/admin-edit-product',
    name: 'admin.product.edit',
    meta: { layout: "admin", requiresAdminAuth: true },
    component: EditProductView
  },
  








  //  dashboard employer section end


  //  dashboard Applicant section start





  






  //  dashboard Applicant section end

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: "custom-active" 
})



router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAdminAuth)) {

    const admin = vcookie.get("admin");


    if (admin) {


      if (admin == "admin") {
     

        next();

      } else {
        next({ name: "login" });


      }

    }
    else {

      next({ name: "login" });




    }
  }

  else {
    next()



  }




});

export default router
