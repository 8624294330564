<template>
    <section class="user-dashboard">
      <!-- Button trigger modal -->
  
      <!-- Modal -->
  
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3>All Users</h3>
        </div>
  
        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box">
                <div class="widget-title">
                  <h4>Categories</h4>
  
                  <div class="chosen-outer">
                    <!-- <router-link
                      class="btn btn-primary"
                      :to="{ name: 'admin.category.add' }"
                    >
                      Add category
                    </router-link> -->
                  </div>
                </div>
  
                <div class="widget-content">
                  <div class="table-outer">
                    <table class="default-table manage-job-table">
                      <thead>
                        <tr>
                          <th>User name</th>
                          <th>Email</th>
                          <th>Photo</th>
                          <th>Phone</th>
                          <th>Role</th>
  
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
  
                      <tbody>
                        <tr v-for="user in users" :key="user.id">
                          <td>
                            <h6>{{ user.name }}</h6>
                          </td>
                       
                          <td >
                            {{ user.email }}
                         
                          </td>
  
                            
                          <td >
                    
                         
                          </td>
  
                            
                          <td >
                            {{ user.phone }}

                         
                          </td>
                          <td >
                            {{ user.role }}

                         
                          </td>
  
  
                          
                          <td class="applied"><a href="#">Active</a></td>
  
                          <td>
                            <div class="option-box">
                              <ul class="option-list">
                                <li>
                                  <button data-text="Reject Aplication">
                                    <span class="la la-pencil"></span>
                                  </button>
                                </li>
                                <li>
                                  <button data-text="Delete Aplication">
                                    <span class="la la-trash"></span>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import axios from "axios";
  import Constant from "@/other/Constant";
  
  export default {
    data() {
      return {
        users: [],
        fileurl: Constant.filebaseurl,
      };
    },
    methods: {
      getUsers() {
        axios
          .get("/admin/users")
          .then((res) => {
            this.users = res.data.data;
          })
          .catch((error) => {
            console.log(error.response);
          });
      },
    },
    mounted: function () {
      this.getUsers();
    },
  };
  </script>
  
  <style>
  </style>