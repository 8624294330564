<template>
  <section class="user-dashboard">
<!-- Button trigger modal -->




<!-- Modal -->


    <div class="dashboard-outer">
      <div class="upper-title-box">
        <h3>All Distributors</h3>
    
      </div>

      <div class="row">
        
        <div class="col-lg-12">
          <!-- Ls widget -->
          <div class="ls-widget">
            <div class="tabs-box">
              <div class="widget-title">
                <h4>Distributors</h4>

                <div class="chosen-outer">
                  <router-link  class="btn btn-primary"  :to="{ name: 'admin.distributor.add' }">
        
            Add distributor
          </router-link>
                </div>
              </div>

              <div class="widget-content">
                <div class="table-outer">
                  <table class="default-table manage-job-table">
                    <thead>
                      <tr>
                        <th>Distributor name</th>
                        <th>Logo</th>
                        <th>Location</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for=" distributor in distributors" :key="distributor"  >
                        <td>
                          <h6>{{ distributor.name }}</h6>
                         
                        </td>
                        <td><img style="height: 50px;width: 50px;"  :src="
                    fileurl+'image/distributor/' +
                    distributor.logo
                  " ></td>


                        <td>{{ distributor.city }}</td>
                      
                        <td class="status">Active</td>
                        <td>
                          <div class="option-box">
                            <ul class="option-list">
                              <li><button data-text="Reject Aplication"><span class="la la-pencil"></span></button></li>
                              <li><button data-text="Delete Aplication"><span class="la la-trash"></span></button></li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                     
                   
                   
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </section>
</template>


<script>
import axios from 'axios';
import Constant from "@/other/Constant"

export default {
data() {
          return {
             distributors:[],
             fileurl: Constant.filebaseurl
          };
      },
      methods:{
          getDistributor(){
              axios.get("admin/distributors",
                
                ).then( res => {
             
                    this.distributors=res.data.data
                 
                 


                  
                }
              )
            .catch(error => {
                console.log(error.response)
            });
          },

       

         
      },
    mounted: function(){
      this.getDistributor()
   
    }

}
</script>

<style>

</style>