import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import store from './store'
import './interceptor/axios'




createApp(App).use(store).use(router).mount('#app')

const app = createApp({})




import MasterView from './views/master/MasterView'
import DashboardMasterView from './views/master/DashboardMasterView'
import AuthMasterView from './views/master/AuthMasterView'




app.component('MasterView',MasterView);
app.component('DashboardMasterView',DashboardMasterView);
app.component('AuthMasterView',AuthMasterView)




