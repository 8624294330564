<template>

<div class="sidebar-backdrop"></div>

<!-- User Sidebar -->
<div class="user-sidebar">
  <div class="sidebar-inner">
    <ul class="navigation nav navbar-nav">
     
      <li  >
        <router-link   :to="{ name: 'admin.dashboard' }">
              <i class="nav-icon fas fa-tachometer-alt sc"></i>
              <p>Dashboard</p>
            </router-link>
      </li>
     
      <li  >
        <router-link   :to="{ name: 'admin.distributor' }">
              <i class="nav-icon fas fa-tachometer-alt sc"></i>
              <p>Distributors</p>
            </router-link>
      </li>
     
      <li  >
        <router-link   :to="{ name: 'admin.category' }">
              <i class="nav-icon fas fa-tachometer-alt sc"></i>
              <p>Categories</p>
            </router-link>
      </li>
      <li>
        <router-link   :to="{ name: 'admin.brand' }">
          <i class="la la-briefcase"></i><p>Brand</p>
        </router-link>
      </li>
      
      <li>
        <router-link   :to="{ name: 'admin.product' }">
          <i class="la la-briefcase"></i><p>Products</p>
        </router-link>
      </li>
      
      <li>
        <router-link   :to="{ name: 'admin.user' }">
          <i class="la la-briefcase"></i><p>Users</p>
        </router-link>
      </li>
      
      <!-- <li>
        <a href="/employer-allapplicants"
          ><i class="la la-file-invoice"></i> All Applicants</a
        >
      </li> -->
     
      <!--li><a href="dashboard-messages.html"><i class="la la-comment-o"></i>Messages</a></li-->
      <li>
        <a href="#"><i class="la la-trash"></i>Delete Profile</a>
      </li>
      <li>
        <a href="#"><i class="la la-lock"></i>Change Password</a>
      </li>

      <!--li><a href="dashboard-company-profile.html"><i class="la la-user-alt"></i>View Profile</a></li-->
      <li>
        <a @click.prevent="logout"><i class="la la-sign-out"></i>Logout</a>
      </li>
    </ul>
  </div>
</div>
  
</template>

<script>
export default {

}
</script>

<style>


.custom-active{
    background-color: #E7EFF9;
}

</style>