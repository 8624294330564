<template>
  <section class="user-dashboard">
<!-- Button trigger modal -->




<!-- Modal -->


    <div class="dashboard-outer">
      <div class="upper-title-box">
        <h3>All Brands</h3>
    
      </div>

      <div class="row">
        
        <div class="col-lg-12">
          <!-- Ls widget -->
          <div class="ls-widget">
            <div class="tabs-box">
              <div class="widget-title">
                <h4>Brands</h4>

                <div class="chosen-outer">
                  <router-link  class="btn btn-primary"  :to="{ name: 'admin.brand.add' }">
        
            Add brand
          </router-link>
                </div>
              </div>

              <div class="widget-content">
                <div class="table-outer">
                  <table class="default-table manage-job-table">
                    <thead>
                      <tr>
                        <th>Brand name</th>
                        <th>Icon</th>
                      
                          <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for=" brand in brands" :key="brand"  >
                        <td>
                          <h6>{{ brand.name }}</h6>
                         
                        </td>

                        <td><img style="height: 50px;width: 50px;"   :src="
                      fileurl+'image/brnad/' +
                      brand.icon
                    " ></td>
                        <td class="applied"><a href="#">Active</a></td>
                       
                  
                        <td>
                          <div class="option-box">
                            <ul class="option-list">
                              <li><button data-text="Reject Aplication">      <router-link   :to="{ name: 'admin.brand.edit' }">
                                    <span class="la la-pencil"></span>
                                  </router-link></button></li>
                              <li><button data-text="Delete Aplication"><span class="la la-trash"></span></button></li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                     
                   
                   
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import Constant from "@/other/Constant"

export default {
data() {
          return {
             brands:[],
             fileurl: Constant.filebaseurl

          };
      },
      methods:{
          getBrand(){
              axios.get("/admin/brands",
                
                ).then( res => {
             
                    this.brands=res.data.data

                 
                 


                  
                }
              )
            .catch(error => {
                console.log(error.response)
            });
          },

       

         
      },
    mounted: function(){
      this.getBrand()
   
    }

}
</script>

<style>

</style>