<template>
  <section @submit.prevent="postjob" class="user-dashboard">
    <div class="dashboard-outer">
      <div class="upper-title-box">
        <h3>Add Category</h3>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <!-- Ls widget -->
          <div class="ls-widget">
            <div class="tabs-box p-3">
              <div class="widget-content">
                <form class="default-form">
                  <div class="row">
                    <!-- Input -->

                    <div class="form-group col-lg-12 col-md-12">
                      <label>Category Name</label>
                      <input
                        type="text"
                        v-model="name"
                        placeholder="Category Name"
                        required
                      />
                    </div>


                    <div class="form-group col-lg-6 col-md-12">
                      <label>Parent</label>
                      <select v-model="parent_id"  >
                        <option disabled value="null">Select a category</option>

                      <option v-for="category in categories" :key="category.id" :value="category.id" >{{ category.name }}</option>
                      </select>
                    </div>
                    <div class="form-group col-lg-6 col-md-12">
                      <label>Status</label>
                      <select v-model="status" >
                        <option disabled value="null">Select a option</option>

                        <option value="1">Enabled</option>
                        <option value="0">Disabled</option>
                      </select>
                    </div>

                    <!-- About Company -->

                    <div class="form-group col-lg-12 col-md-12">
                      <label>Description</label>
                      <textarea
                        v-model="details"
                        placeholder="Description"
                        required
                      ></textarea>
                    </div>

                    <div class="form-group col-lg-6 col-md-12">
                      <label>Logo</label>
                      <input
                        class="form-control"
                        type="file"
                        @change="handleFileUpload"
                        placeholder="Total program"
                        required
                      />

                      <img v-if="file" :src="imageUrl" class="p-1 mt-2 border" style="height: 80px;width: 80px;">
                    </div>

                    <!-- Input -->
                    <div class="form-group col-lg-12 col-md-12 text-right">
                      <button
                        @click.prevent="addCategory"
                        class="theme-btn btn-sm btn-style-one"
                      >
                        Add category
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";

// import moment from 'moment';

export default {
  name: "AdminAddJobCategoryView",
  data() {
    return {
      file: "",
      imageUrl:"",
      categories:[],
      parent_id:null,
      status:null



    };
  },
  methods: {
    addCategory() {
      var formData = new FormData();
      formData.append("icon", this.file);
      formData.append("name", this.name);
      formData.append("parent_id", this.parent_id);
      formData.append("details", this.details);
      formData.append("status", this.status);

      axios
        .post(
          "/admin/categories",

          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          if (res.status == 201) {
            this.$router.push({ name: "admin.category" });
            this.$emit("get_message", res.data.message);
          }
        })
        .catch((error) => {
          console.log(error.response);
        });


    },

    getCategory() {
      axios
        .get("/admin/categories")
        .then((res) => {
          this.categories = res.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    handleFileUpload(event) {
      this.file = event.target.files[0];
      if (this.file) {
        // Use FileReader to read file and generate a preview
        const reader = new FileReader();

        reader.onload = (e) => {
          this.imageUrl = e.target.result;
        };

        reader.readAsDataURL(this.file);
      }
      
    },
  },
  mounted: function () {
    this.getCategory();
  },
};
</script>



