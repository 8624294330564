<template>
  <div>
    <!-- <div id="popbg">f</div> -->

    <div id="homepopup" style="display: none">
      <i @click="pophide" class="fa-solid fa-xmark popicon"></i>
      <div
        style="height: 100%"
        id="carouselExampleSlidesOnly"
        class="carousel"
        data-ride="carousel"
      >
        <div style="height: 100%" class="carousel-inner">
          <div
            style="object-fit: cover"
            v-for="popupad in popupads"
            :key="popupad.id"
            class="carousel-item active bgp2"
            v-bind:class="condition ? 'class_if_is_true' : 'else_class'"
          >
            <img
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
              :src="popupad.background"
            />
          </div>
        </div>
      </div>
    </div>

    <div id="shortside" class="d-none">
      <div style="height: 50%">
        <div class="shortbtn1 text-center pt-2">
          <a href="" style="color: white">PART TIME JOBS</a>
        </div>
      </div>
      <div style="height: 50%">
        <div style="height: 50%">
          <div class="shortbtn2 text-center pt-2">
            <a href="/" style="color: black">DIRECT HIRING</a>
          </div>
        </div>
      </div>
    </div>

    <!-- Banner Section-->
    <section class="banner-section">
      <!-- <div id="mySidenav" class="sidenav">
          <a href="/parttimejob" id="about">Part-time Job</a>
          <a href="#" id="blog">Direct Hire</a>
          <a href="#" id="projects">Summer Job</a>
          <a href="#" id="contact">Feature Job</a>
    </div> -->
      <div class="auto-container">
        <div class="row">
          <div class="content-column col-lg-7 col-md-12 col-sm-12">
            <div class="inner-column wow fadeInUp" data-wow-delay="1000ms">
              <div class="title-box">
                <h3>The Launchpad for Your Business Expansion</h3>
                <h5 class="text-small-white">and Entrepreneurial Success!</h5>
              </div>

              <!-- Job Search Form -->
              <div class="job-search-form">
                <form>
                  <div class="row">
                    <div class="form-group col-lg-6 col-md-12 col-sm-12">
                      <span class="icon flaticon-search-1"></span>
                      <input
                        type="text"
                        class="font110"
                        v-model="search_key"
                        placeholder=" Title, keywords, or Distributors"
                      />
                    </div>
                    <!-- Form Group -->
                    <div
                      class="form-group col-lg-3 col-md-12 col-sm-12 location"
                    >
                      <span class="icon flaticon-map-locator"></span>
                      <input type="text" v-model="location" placeholder="City" />
                    </div>
                    <!-- Form Group -->
                    <div
                      class="form-group col-lg-3 col-md-12 col-sm-12 btn-box"
                    >
                      <button
                       @click.prevent="search"
                        class="btn-style-one theme-color-bg2"
                      >
                        <span class="btn-title"> FIND </span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <!-- Job Search Form -->

              <!-- Popular Search -->
              <div class="text-white font140">Popular Searches :</div>
              <div class="mt-3 mb-5" style="cursor: pointer">
                <button class="theme-button3 font120 text-white">
                  <span class="icon flaticon-search-1"></span> offer
                </button>
                <button class="theme-button3 font120 text-white" href="#">
                  <span class="icon flaticon-search-1"></span> Product
                </button>
                <button class="theme-button3 font120 text-white" href="#">
                  <span class="icon flaticon-search-1"></span> Distributor
                </button>
                <button class="theme-button3 mt-2 font120 text-white" href="#">
                  <span class="icon flaticon-search-1"></span> Brand
                </button>
              </div>

              <!-- <div class="mt-4 mb-5">
                <button class="theme-button4 font120">
                  <a class="theme-color2" href="/parttimejob"> PART TIME JOB</a>
                </button>
                <button href="/directhire" class="theme-button4 mt-2 font120">
                  <a class="theme-color2" href="/directhire">DIRECT HIRE</a>
                </button>
                <button class="theme-button4 mt-2 font120">
                  <a class="theme-color2" @click.prevent="opentierjob"
                    >TIER 2 SPONSORSHIP</a
                  >
                </button>
              </div> -->
              <!-- End Popular Search -->
            </div>
          </div>

          <div class="image-column col-lg-5 col-md-12">
            <div class="image-box">
              <div class="main-image wow fadeIn" data-wow-delay="500ms">
                <img style="height:450px;" src="images/boy.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="features__section">
      <div class="auto-container">
        <div class="row features__grid justify-content-between">
          <div class="col-lg-3 col-md-6">
            <div class="features -type-1">
              <div class="icon-wrap">
                <div><i class="bi bi-clock-history theme-color2"></i></div>
              </div>
              <div class="title">
                <a href="/" class="theme-color2">20K+ Brands </a>
              </div>
            </div>
          </div>

          <div class="col-lg-auto features-line-col">
            <div class="features-line"></div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="features -type-1">
              <div class="icon-wrap">
                <div><i class="fa-solid fa-person theme-color2"></i></div>
              </div>

              <div class="title">
                <a href="/" class="theme-color2">100+ Distributors</a>
              </div>
            </div>
          </div>

          <div class="col-lg-auto features-line-col">
            <div class="features-line"></div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="features -type-1">
              <div class="icon-wrap">
                <div><i class="las la-person-booth theme-color2"></i></div>
              </div>
              <div class="title">
                <a @click.prevent="" class="theme-color2">Category</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="top-company-section playout-pt-60 layout-pb-60">
      <div class="auto-container">
        <div class="row justify-content-between align-items-end">
          <div class="col-lg-12">
            <div class="sec-title mb-0">
              <br /><br />
              <h2 class="color-blue-dark">
                Featured Distributorship Opportunities
              </h2>
              <div class="text font110"></div>
            </div>
          </div>

          <!-- <div class="col-auto">
            <a href="#" class="button -arrow text-dark-blue">
              Browse
              <span class="fa fa-angle-right"></span>
            </a>
          </div> -->
        </div>

        <div class="row">
          <div
          @click="showdistributor(distributor.id)"
            v-for="distributor in distributors"
            :key="distributor"
            class="col-lg-3"
          >
            <div class="company-block">
              <div class="inner-box">
                <img
                  :src="
                    fileurl+'image/distributor/' +
                    distributor.logo
                  "
                  style="height: 200px"
                  alt=""
                />
                <div class="text-left">
                  <h4 class="text-bold name mt-2">{{ distributor.name }}</h4>

                  <p>
                    Investment price range :
                    {{ distributor.investment_required }}
                  </p>
                  <p>Established : {{ 2020 }}</p>
                  <p>Distributor of : {{ distributor.established }}</p>
                  <!-- <div class="location">
  <i class="flaticon-map-locator"></i> China
</div> -->
                </div>

                <a
                  href="#"
                  style="
                    border-radius: 20px;
                    background-color: rgb(246, 61, 20);
                  "
                  class="btn text-white p-2 w-100 mt-2"
                  >Call Now</a
                >

                <a
                  class="text-bold text-black"
                  style="margin-top: 10px !important"
                  >View datails</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- End Banner Section-->
    <!-- Job Categories -->

    <!-- End Job Categories -->
    <!-- Job Categories -->
    <!--section class="job-categories style-two">
      <div class="auto-container">
        <div class="sec-title text-center">
          <h2>Popular Job Categories</h2>
          <div class="text">2020 jobs live - 293 added today.</div>
        </div>

        <div class="row wow fadeInUp"-->
    <!-- Category Block -->
    <!--div v-for="category in categories" :key="category.id" class="category-block col-lg-4 col-md-6 col-sm-12">
            <div class="inner-box">
              <div class="content">
                <span class="icon flaticon-money-1"></span>
                <h4><a href="#">{{category.cat_name}}</a></h4>
                <p>(50 open position)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section-->
    <!-- End Job Categories -->

    <!-- Commercial Ad Section -->

    <section class="testimonial-section">
      <div class="container-fluid">
        <!-- Sec Title -->
        <div class="sec-title text-center">
          <h2>Why you choose lightspeed?</h2>
          <div class="text">Lorem ipsum dolor sit amet elit, sed do eiusmod tempor</div>
        </div>

        <div class="carousel-outer wow fadeInUp">

          <!-- Testimonial Carousel -->
          <div class="testimonial-carousel owl-carousel owl-theme">

            <!--Testimonial Block -->
            <div class="testimonial-block">
              <div class="inner-box">
                <h4 class="title">Good theme</h4>
                <div class="text">Without JobHunt i’d be homeless, they found me a job and got me sorted out quickly with everything! Can’t quite… The Mitech team works really hard to ensure high level of quality</div>
                <!-- <div class="info-box">
                  <div class="thumb"><img src="images/resource/testi-thumb-1.png" alt=""></div>
                  <h4 class="name">Nicole Wells</h4>
                  <span class="designation">Web Developer</span>
                </div> -->
              </div>
            </div>

            <!--Testimonial Block -->
            <div class="testimonial-block">
              <div class="inner-box">
                <h4 class="title">Great quality!</h4>
                <div class="text">Without JobHunt i’d be homeless, they found me a job and got me sorted out quickly with everything! Can’t quite… The Mitech team works really hard to ensure high level of quality</div>
                <!-- <div class="info-box">
                  <div class="thumb"><img src="images/resource/testi-thumb-2.png" alt=""></div>
                  <h4 class="name">Gabriel Nolan</h4>
                  <span class="designation">Consultant</span>
                </div> -->
              </div>
            </div>

            <!--Testimonial Block -->
            <div class="testimonial-block">
              <div class="inner-box">
                <h4 class="title">Awesome Design </h4>
                <div class="text">Without JobHunt i’d be homeless, they found me a job and got me sorted out quickly with everything! Can’t quite… The Mitech team works really hard to ensure high level of quality</div>
                <!-- <div class="info-box">
                  <div class="thumb"><img src="images/resource/testi-thumb-3.png" alt=""></div>
                  <h4 class="name">Ashley Jenkins</h4>
                  <span class="designation">Designer</span>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="job-section-five style-two">
      <div class="auto-container">
        <div class="sec-title ">
          <h2 class="theme-color2">Exclusive Brands</h2>

          <!-- <h5 class="mt-2">Check the Best offer</h5> -->
        </div>

        <div style="z-index: 9" class="row wow fadeInUp ">
          <div @click="showbrand(brand.id)" v-for="brand in brands" :key="brand" class="col-md-3 ">
            <div style="border-radius: 30px;" class="custom-card p-3 mt-2">
              <div class="row">
                <div class="col-md-4 ">
                  <img class="border"
                    style="height: 60px; width: 60px;border-radius: 50%;"
                    :src="
                      fileurl+'image/brnad/' +
                      brand.icon
                    "
                  />
                </div>

                <div  v-if="brand.category" class="col-md-8 mt-sm-2">
                         <span style="font-size: 12px;background-color: #e8ffff ;padding: 2px 20px;border-radius: 15px;color: #53cecb;" class="">
                          {{brand.category.name}}
                         </span>
             
                  <h6
                    class="font110 mt-2"
                    style="text-align: left; font-weight: 500"
                  >
                  {{ brand.name}}
                  </h6>

                  
              
                </div>
                
                <div class="row">
                    <div class="col-12">
                      <!-- <span class="icon flaticon-briefcase"></span> -->

                      <span class="small-font ">
                        Investment price range</span
                      >
                    </div>

                    <p class="text-black">1.00Lac-4.00Lac</p>

                  
                  </div>



              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <!-- End Testimonial Section -->

    <!-- steps Section -->
    <section class="steps-section pt-0">
      <div class="auto-container">
        <div class="row">
          <div class="image-column mt-4 col-lg-7 col-md-12 col-sm-12">
            <div class="inner-column wow fadeInUp p-5">
              <figure class="">
                <img
                  height="100%"
                  width="110%"
                  src="images/resource/man.jpg"
                  alt=""
                />
              </figure>
              <!-- Count Employers -->
              <!--div class="count-employers wow fadeInUp">
                <span class="title">300k+ Employers</span>
                <figure class="image"><img src="images/resource/multi-peoples.png" alt=""></figure>
              </div-->
            </div>
          </div>

          <div class="content-column col-lg-5 col-md-12 col-sm-12">
            <div class="inner-column wow fadeInUp">
              <div class="sec-title">
                <h2>STEPS OF JOINING</h2>
                <h5 class="text font110"></h5>
                <ul class="steps-list">
                  <li>
                    <span class="count">1</span> Need to prepare application
                    documents for submission
                  </li>
                 
                  <li>
                    <span class="count">2</span> Need to prepare application
                    documents for submission
                  </li>
                 
                  <li>
                    <span class="count">3</span> Need to prepare application
                    documents for submission
                  </li>
                 
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    
    <section class="job-section-five style-two">
      <div class="auto-container">
        <div class="sec-title ">
          <h2 class="theme-color2">Choose your product</h2>

          <!-- <h5 class="mt-2">Check the Best offer</h5> -->
        </div>

        <div style="z-index: 9" class="row wow fadeInUp ">
          <div @click="showProduct(product.id)" v-for="product in products" :key="product" class="col-md-3 ">
            <div style="border-radius: 30px;" class="custom-card p-3 mt-2">
              <div class="row">
                <div class="col-md-12 text-center ">
                  <img class=""
                    style="height:200px; width: 100"
                    :src="
                      fileurl+'image/product/' +
                      product.probduct_thum
                    "
                  />
                </div>

                <div v-if="product.category"  class="col-md-12 mt-sm-2">
                         <span style="font-size: 12px;background-color: #e8ffff ;padding: 2px 20px;border-radius: 15px;color: #53cecb;" class="">
                         {{ product.category.name}}
                         </span>
             
                  <h6
                    class="font110 mt-2"
                    style="text-align: left; font-weight: 500"
                  >
                  {{ product.title}}
                  </h6>

                  
              
                </div>
                
                <div class="row">
                    <div class="col-12">
                      <!-- <span class="icon flaticon-briefcase"></span> -->

                      <span class="small-font ">
                        Investment price range</span
                      >
                    </div>

                    <p class="text-black">1.00Lac-4.00Lac</p>

                  
                  </div>



              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <!-- End Steps Section -->

    <!-- Job Section -->

    <section class="job-section-five style-two iam">
      <div class="auto-container">
        <div class="row wow fadeInUp">
          <div class="recent-column col-xl-5 col-lg-12 col-md-12 col-sm-12">
            <div class="content-column">
              <div class="inner-column iamrec wow fadeInUp">
                <div class="sec-title">
                  <h2>ARE YOU A DISTRIBUTOR ?</h2>
                  <h5 class="text font110 text-justify">
                    You will get a high services from us!
                  </h5>
                </div>

                <a
                  style="font-size: 110%; z-index: 30; margin-top: 20px"
                  href="/"
                  class="btn-style-one theme-color-bg"
                  >Apply Now</a
                >
              </div>
            </div>
          </div>

          <div class="featured-column col-xl-7 col-lg-12 col-md-12 col-sm-12">
            <div class="image-column">
              <figure>
                <img src="images/business1.jpg" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Job Section -->
    <!-- End Job Section -->

    <!-- Recruiter Section -->
    <!-- <section class="recruiter-section">
      <div class="outer-box">
        <div class="image-column">
          <figure><img src="images/resource/Recruiter.jpg" alt="" /></figure>
        </div>
        <div class="content-column">
          <div class="inner-column wow fadeInUp">
            <div class="sec-title">
              <h2>I am Recruiter</h2>
              <div class="text">
                One of our One of our jobs has some kind of flexibility jobs has
                some kind of flexibility option such as telecommuting, a
                part-time schedule or a flexible or flextime.
              </div>
              <a
                style="font-size: 130%; z-index: 30"
                href="/login"
                class="theme-btn btn-style-one"
                >Post New Job</a
              >
            </div>
          </div>

        </div>
      </div>
    </section> -->
    <!-- End Recruiter Section -->
    <!-- Top Companies -->
    <br />

    <section class="job-categories">
      <div class="auto-container">
        <div class="sec-title">
          <h2>Top Trending Categories</h2>
          <h6 class="mt-3">
            <span class="theme-color bold-font">
    
            </span>
          </h6>
        </div>

        <div class="row wow fadeInUp">
          <!-- Category Block -->
          <div
            v-for="category in categories"
            :key="category"
            class="category-block col-lg-3 col-md-6 col-sm-12"
          >
            <div @click="categoryproduct(category.id,category.name)" style="background-color: rgb(255, 248, 244)" class="inner-box">
              <div class="row">
                <div class="col-3">
                  <img
                    style="height: 50px; width: 50px"
                    :src="
                      fileurl+'image/category/' +
                      category.icon
                    "
                    alt=""
                  />
                </div>
                <div class="col-9">
                  <h4 class="mt-1">
                    <a href="#">{{ category.name }}</a>
                  </h4>
                  <h6>
                    <span class="theme-color"> {{ category.name }}</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--End Page Title-->

    <!-- Testimonials Section -->
    
    <!-- End Testimonials Section -->

    <!-- Call To Action Three -->
    <section class="let-section  call-to-action-three theme-color-bg4">
      <div class="auto-container">
        <div class="outer-box">
          <div class="sec-title">
            <h2>Let find you</h2>
            <div class="text">
              Since lightspeed is a premium job board that continuously earns the
              top spot in our rankings for  distributor, we advise
              you to give it a try.
            </div>
          </div>
          




          <div class="btn-box">
            <a href="/job" class="theme-btn btn-style-one theme-color-bg"
              ><span class="btn-title">Search Distributors</span></a
            >
          </div>
        </div>
      </div>
    </section>
    <!-- End Call To Action -->
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import Constant from "@/other/Constant"

export default {
  name: "HomeView",
  data() {
    return {
      show: false,
      load: false,
      search_key: "",
      popupads: [],
      adboards: [],
      distributors: [],
      categories: [],
      products: [],
      brands: [],
      fileurl: Constant.filebaseurl
    };
  },

  methods: {
    getcategory() {
      axios
        .get("/public/categories")
        .then((res) => {
          this.categories = res.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getbrand() {
      axios
        .get("/public/brands")
        .then((res) => {
          this.brands = res.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    popup() {
      $("#homepopup").addClass("popshow");
      $("#popbg").addClass("lowopacity");
      $("body").addClass("bodylock");
      $("html").addClass("bodylock");
    },

    pophide() {
      $("#homepopup").addClass("pophide");
      $("#popbg").addClass("highopacity");
      $("body").addClass("bodyunlock");
      $("html").addClass("bodyunlock");
    },

   

    getDistributors() {
      axios
        .get("/public/distributors")
        .then((res) => {
          if (res.status == 200) {
            this.distributors = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getProducts() {
      axios
        .get("/public/products")
        .then((res) => {
          if (res.status == 200) {
            this.products = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    // getpoup() {
    //   axios
    //     .get("anonymous/popup_ad_board/", { ignore: "1" })
    //     .then((res) => {
    //       this.popupads = res.data;

    //       if (res.status == 200) {
    //         console.log("ok");
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error.response);
    //     });
    // },

    showdistributor(id) {
      if (id) {
        this.$router.push({ name: "distributor-single", query: { id: id } });
      }
    },
    showbrand(id) {

      this.$router.push({ name: "distributor-single", query: { bid: id } });

      
    },

    showProduct(id) {
      if (id) {
        this.$router.push({ name: "product-single", query: { id: id } });
      }
    },
    search() {
      if (this.search_key) {
        this.$router.push({ name: "search", query: { key: this.search_key, } });
      }
    },
    categoryproduct(id,name) {
      if (id) {
        this.$router.push({ name: "category-public", query: { id:id, name:name} });
      }
    },
  },

  mounted: function () {
    this.getDistributors();
    this.getcategory();
    this.getbrand();
    this.getProducts();
    
  },
};
</script>

<style>
#mySidenav {
  position: fixed;

  top: 250px;

  right: 0;
}

/* Style the links inside the sidenav */
#mySidenav a {
  position: absolute; /* Position them relative to the browser window */
  left: -20px; /* Position them outside of the screen */
  transition: 0.3s; /* Add transition on hover */
  padding: 15px 15px 15px 30px; /* 15px padding */
  width: 150px; /* Set a specific width */
  text-decoration: none; /* Remove underline */
  text-orientation: sideways-right;
  font-size: 20px; /* Increase font size */
  color: #ff9f9f; /* White text color */
  border-radius: 0 5px 5px 0; /* Rounded corners on the top right and bottom right side */
  overflow: hidden; /* Hide scrollbars */
  overflow-y: hidden; /* Hide vertical scrollbar */
  /*overflow-x: hidden; /* Hide horizontal scrollbar */
  z-index: 14;
  opacity: 100% !important;
}

#mySidenav a:hover {
  left: 0; /* On mouse-over, make the elements appear as they should */
}

/* The about link: 20px from the top with a green background */
#about {
  top: 20px;

  background-color: #04aa6d;
}

#blog {
  top: 80px;
  background-color: #2196f3; /* Blue */
}

#projects {
  top: 140px;
  background-color: #f44336; /* Red */
}

#contact {
  top: 200px;
  background-color: #555; /* Light Black */
}
</style>
