<template>
    <section class="job-detail-section">
      <div style="margin-top: 50px" class="job-detail-outer">
        <div class="auto-container">
          <div class="row">
            <p>Distributors > Global > WheelsHdpe Sheet</p>
  

           
  
            <div class="mt-4" > 
  
              <h3>{{name}}</h3>
  
              <div class="row mt-3">
                <div @click="showProduct(product.id)" v-for="product in products" :key="product" class="col-md-3 ">
            <div style="border-radius: 30px;" class="custom-card p-3 mt-2">
              <div class="row">
                <div class="col-md-12 text-center ">
                  <img class=""
                    style="height:200px; width: 100"
                    :src="
                      fileurl+'image/product/' +
                      product.probduct_thum
                    "
                  />
                </div>

                <div class="col-md-12 mt-sm-2">
                         <span style="font-size: 12px;background-color: #e8ffff ;padding: 2px 20px;border-radius: 15px;color: #53cecb;" class="">
                          Food & Beverage
                         </span>
             
                  <h6
                    class="font110 mt-2"
                    style="text-align: left; font-weight: 500"
                  >
                  {{ product.title}}
                  </h6>

                  
              
                </div>
                
                <div class="row">
                    <div class="col-12">
                      <!-- <span class="icon flaticon-briefcase"></span> -->

                      <span class="small-font ">
                        Investment price range</span
                      >
                    </div>

                    <p class="text-black">1.00Lac-4.00Lac</p>

                  
                  </div>



              </div>
            </div>
          </div>
        </div>
  
  
  
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
    
    <script>
  import axios from "axios";
  import Constant from "@/other/Constant"
  export default {
    data() {
      return {
        products:[],
        name:'',
      
        fileurl: Constant.filebaseurl

      
      };
    },
  
    methods: {

        getProducts(id) {
      axios
        .get("/public/products/category/" +id)
        .then((res) => {
          if (res.status == 200) {
            this.products = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  

   
    },
  
    mounted() {
       var id = this.$route.query.id;
       this.name = this.$route.query.name;
  
      this.getProducts(id)
    },
  };
  </script>
    
    