<template>
  <section class="user-dashboard">
    <!-- Button trigger modal -->

    <!-- Modal -->

    <div class="dashboard-outer">
      <div class="upper-title-box">
        <h3>All Categories</h3>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <!-- Ls widget -->
          <div class="ls-widget">
            <div class="tabs-box">
              <div class="widget-title">
                <h4>Categories</h4>

                <div class="chosen-outer">
                  <router-link
                    class="btn btn-primary"
                    :to="{ name: 'admin.category.add' }"
                  >
                    Add category
                  </router-link>
                </div>
              </div>

              <div class="widget-content">
                <div class="table-outer">
                  <table class="default-table manage-job-table">
                    <thead>
                      <tr>
                        <th>Category name</th>
                        <th>Parent</th>
                        <th>Icon</th>

                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="category in categories" :key="category">
                        <td>
                          <h6>{{ category.name }}</h6>
                        </td>
                        <td v-if="category.parent">
                          <h6>{{ category.parent.name }}</h6>
                        </td>
                        <td v-else>
                       
                        </td>


                        <td>
                          <img
                            style="height: 50px; width: 50px"
                            :src="fileurl + 'image/category/' + category.icon"
                          />
                        </td>
                        <td class="applied"><a href="#">Active</a></td>

                        <td>
                          <div class="option-box">
                            <ul class="option-list">
                              <li>
                                <button data-text="Reject Aplication">
                                  

                                  <router-link   :to="{ name: 'admin.category.edit' }">
                                    <span class="la la-pencil"></span>
                                  </router-link>
                                </button>
                              </li>
                              <li>
                                <button data-text="Delete Aplication">
                                  <span class="la la-trash"></span>
                                </button>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Constant from "@/other/Constant";

export default {
  data() {
    return {
      categories: [],
      fileurl: Constant.filebaseurl,
    };
  },
  methods: {
    getCategory() {
      axios
        .get("/admin/categories")
        .then((res) => {
          this.categories = res.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
  mounted: function () {
    this.getCategory();
  },
};
</script>

<style>
</style>