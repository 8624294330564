<template>
   <!--Page Title-->

   <section class="ls-section mt-5">
      <div class="auto-container">
        <div class="filters-backdrop"></div>

        <div class="row">

          <!-- Filters Column -->
          <div class="filters-column col-lg-3 col-md-12 col-sm-12">
            <div class="inner-column">
              <div class="filters-outer">
                <button type="button" class="theme-btn close-filters">X</button>

                <!-- Filter Block -->
                <div class="filter-block">
                  <h4>Search by Keywords</h4>
                  <div class="form-group">
                    <input v-model="key" @input="search(cat_id,key)" type="text" name="listing-search" placeholder="Distributor,Brand">
                    <span class="icon flaticon-search-3"></span>
                  </div>
                </div>

                <!-- Filter Block -->
                <div class="filter-block">
                  <h4>Location</h4>
                  <div class="form-group">
                    <input type="text" name="listing-search" placeholder="City or postcode">
                    <span class="icon flaticon-map-locator"></span>
                  </div>
                
                </div>

                <!-- Filter Block -->
                <div class="filter-block">
                  <h4>Category</h4>
                  <div class="form-group">
                    <select @change="getCategory(cat_id)" v-model="cat_id" >
                      <option value="" >Choose a category</option>
                      <option v-for="category in categories" :key="category" :value="category.id">{{ category.name }}</option>
                    
                    </select>
                    <span class="icon flaticon-briefcase"></span>
                  </div>
                </div>

                <!-- Switchbox Outer -->
             

                <!-- Checkboxes Ouer -->
              

                <!-- Checkboxes Ouer -->
             

                <!-- Filter Block -->
         

            
              </div>

          
              <!-- End Call To Action -->
            </div>
          </div>

          <!-- Content Column -->
          <div class="content-column col-lg-9 col-md-12 col-sm-12">

            <h3>{{category.name}} Distributors</h3>


            <p class="mt-3 tex-justify">Bathing, washing and related activities are impossible without a soap. Our offered bathing soaps are composed of both cleaning agents and moisturisers. Usually, people give priority to soaps which have good moisturising properties and could make their skin softer & smoother. Such bars have 'cocoa butter', 'Shea butter', 'coconut oil' & 'glycerine' etc.. There are also many skin whitening or fairness soaps in the market that women prefer these days. Furthermore, to heal the skin related problems such as anti-bacterial soaps, anti-acne soaps, herbals soaps are some variant soaps available. With so many options people can now also choose bathing soaps as per their skin types, be it dry, oily or sensitive skin. Become a distributor , wholesale supplier & dealer of Soap & Hand Wash like sanitizer, liquid soap, antibacterial soap, liquid hand wash, bath soap, hand sanitizer, handmade soap, glycerin soap, laundry soap, beauty soap, homemade soap, alcohol based sanitizer and many more.</p>
            <div class="ls-outer">
              <button type="button" class="theme-btn btn-style-two toggle-filters">Show Filters</button>

            


              <!-- Job Block -->
              <div class="job-block mt-3 ">
              
                <div class="inner-box mt-3">
                  <div class="content">
                    <div class="row">
                      <div
            v-for="category in subcategories"
            :key="category"
            class="category-block col-lg-3 col-md-6 col-sm-12"
          >
            <div @click="catchange(category.id)" style="background-color: rgb(247 247 247);padding: 10px;" class="inner-box">
              <div class="row">
                <div class="col-3">
                  <img
                    style="height: 20px; width: 20px"
                    :src="
                      fileurl+'image/category/' +
                      category.icon
                    "
                    alt=""
                  />
                </div>
                <div class="col-9">
                  <p class="mt-1">
                    <a class="text-black"  href="#">{{ category.name }}</a>
                  </p>
                  
                </div>
              </div>


           
            </div>
            
        
          </div>
                


                    </div>
           
                  </div>
                  <a href="#" class="theme-button px-4 py-2">Load more</a>

                </div>

              </div>

              <div class="row">
          <div
          @click="showdistributor(distributor.id)"
            v-for="distributor in distributors"
            :key="distributor"
            class="col-lg-4"
          >
            <div class="company-block">
              <div class="inner-box">
                <img
                  :src="
                    fileurl+'image/distributor/' +
                    distributor.logo
                  "
                  style="height: 200px"
                  alt=""
                />
                <div class="text-left">
                  <h4 class="text-bold name mt-2">{{ distributor.name }}</h4>

                  <p>
                    Investment price range :
                    {{ distributor.investment_required }}
                  </p>
                  <p>Established : {{ 2020 }}</p>
                  <p>Distributor of : {{ distributor.established }}</p>
                  <!-- <div class="location">
  <i class="flaticon-map-locator"></i> China
</div> -->
                </div>

                <a
                  href="#"
                  style="
                    border-radius: 20px;
                    background-color: rgb(246, 61, 20);
                  "
                  class="btn text-white p-2 w-100 mt-2"
                  >Call Now</a
                >

                <a
                  class="text-bold text-black"
                  style="margin-top: 10px !important"
                  >View datails</a
                >
              </div>
            </div>
          </div>
        </div>
 


              <div style="z-index: 9" class="row wow fadeInUp ">
          <div @click="showProduct(product.id)" v-for="product in products" :key="product" class="col-md-3 ">
            <div style="border-radius: 30px;" class="custom-card p-3 mt-2">
              <div class="row">
                <div class="col-md-12 text-center ">
                  <img class=""
                    style="height:200px; width: 100"
                    :src="
                      fileurl+'image/product/' +
                      product.probduct_thum
                    "
                  />
                </div>

                <div  class="col-md-12 mt-sm-2">
                         <span style="font-size: 12px;background-color: #e8ffff ;padding: 2px 20px;border-radius: 15px;color: #53cecb;" class="">
                          Food & Beverage
                         </span>
             
                  <h6
                    class="font110 mt-2"
                    style="text-align: left; font-weight: 500"
                  >
                  {{ product.title}}
                  </h6>

                  
              
                </div>
                
                <div class="row">
                    <div class="col-12">
                      <!-- <span class="icon flaticon-briefcase"></span> -->

                      <span class="small-font ">
                        Investment price range</span
                      >
                    </div>

                    <p class="text-black">1.00Lac-4.00Lac</p>

                  
                  </div>



              </div>
            </div>
          </div>

        </div>

        <h3> Brands</h3>
        <div style="z-index: 9" class="row wow fadeInUp ">
          <div @click="showbrand(brand.id)" v-for="brand in brands" :key="brand" class="col-md-4 ">
            <div style="border-radius: 30px;" class="custom-card p-3 mt-2">
              <div class="row">
                <div class="col-md-4 ">
                  <img class="border"
                    style="height: 60px; width: 60px;border-radius: 50%;"
                    :src="
                      fileurl+'image/brnad/' +
                      brand.icon
                    "
                  />
                </div>

                <div v-if="brand.category" class="col-md-8 mt-sm-2">
                         <span style="font-size: 12px;background-color: #e8ffff ;padding: 2px 20px;border-radius: 15px;color: #53cecb;" class="">
                          {{ brand.category.name }}
                         </span>
             
                  <h6
                    class="font110 mt-2"
                    style="text-align: left; font-weight: 500"
                  >
                  {{ brand.name}}
                  </h6>

                  
              
                </div>
                
                <div class="row">
                    <div class="col-12">
                      <!-- <span class="icon flaticon-briefcase"></span> -->

                      <span class="small-font ">
                        Investment price range</span
                      >
                    </div>

                    <p class="text-black">1.00Lac-4.00Lac</p>

                  
                  </div>



              </div>
            </div>
          </div>

        </div>



     
       


           
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--End Page Title-->

    <!-- Listing Section -->
   
  </template>
    
    <script>
  import axios from "axios";
  import Constant from "@/other/Constant"
  export default {
    data() {
      return {
        products:[],
        name:'',
        category:{},
        subcategories:[],
        distributors:[],
        categories:[],
        brands:[],
        key:"",
      
        fileurl: Constant.filebaseurl,
        cat_id:""

      
      };
    },
  
    methods: {

        getProducts(id) {
      axios
        .get("/public/products/category/" +id)
        .then((res) => {
          if (res.status == 200) {
            this.products = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    getCategory(id){
      axios
        .get("/public/categories/show/" +id)
        .then((res) => {
          if (res.status == 200) {
            this.category = res.data.data;
            this.subcategories = res.data.data.subcategories;
            this.getDistributor(this.cat_id)

    
             this.getbrand(this.cat_id)
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    getallcategory() {
      axios
        .get("/public/categories")
        .then((res) => {
          this.categories = res.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getDistributor(id){
      axios
        .get("/public/distributor/category/" +id)
        .then((res) => {
          if (res.status == 200) {
            this.distributors = res.data.data;
       
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    search(categoryid,key){
      axios
        .get("/public/distributor/category/search/" +categoryid+"/"+key)
        .then((res) => {
          if (res.status == 200) {
            this.distributors = res.data.data;
       
          }
        })
        .catch((error) => {
          console.log(error.response);
        });

        axios
        .get("/public/brand/category/search/" +categoryid+"/"+key)
        .then((res) => {
          if (res.status == 200) {
            this.brands = res.data.data;
       
          }
        })
        .catch((error) => {
          console.log(error.response);
        });




    },
    catchange(id){
      this.cat_id=id
      this.getCategory(this.cat_id)

    },
  
  
    getbrand(id){
      axios
        .get("/public/brand/category/" +id)
        .then((res) => {
          if (res.status == 200) {
            this.brands = res.data.data;
       
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    showdistributor(id) {
      if (id) {
        this.$router.push({ name: "distributor-single", query: { id: id } });
      }
    },
    showbrand(id) {

      this.$router.push({ name: "distributor-single", query: { bid: id } });

      
    },

  
  

   
    },
  
    mounted() {
       var id = this.$route.query.id;
       this.cat_id=id
       this.name = this.$route.query.name;
       this.getDistributor(this.cat_id)
       this.getCategory(this.cat_id)
       this.getallcategory(this.cat_id)
       this.getbrand(this.cat_id)
    },
  };
  </script>
    
    