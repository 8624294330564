<template>
  <section @submit.prevent="postjob" class="user-dashboard">
    <div class="dashboard-outer">
      <div class="upper-title-box">
        <h3>Add Distributor</h3>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <!-- Ls widget -->
          <div class="ls-widget">
            <div class="tabs-box p-3">
              <div class="widget-content">
                <form class="default-form">
                  <div class="row">
                    <!-- Input -->

                    <div class="form-group col-lg-12 col-md-12">
                      <label>Distributor Name</label>
                      <input
                        type="text"
                        v-model="name"
                        placeholder="Distributor  Name"
                        required
                      />
                    </div>

                    <div class="form-group col-lg-6 col-md-12">
                      <label>Mode</label>
                      <input type="text" v-model="mode" placeholder="mode" />
                    </div>
                    <div class="form-group col-lg-6 col-md-12">
                      <label>slug</label>
                      <input type="text" v-model="slug" placeholder="mode" />
                    </div>
                    <div class="form-group col-lg-6 col-md-12">
                      <label>Investment Required</label>
                      <input
                        type="text"
                        v-model="investment_required"
                        placeholder="Investment Required"
                      />
                    </div>
                    <div class="form-group col-lg-6 col-md-12">
                      <label>GST NO.</label>
                      <input
                        type="text"
                        v-model="gst_no"
                        placeholder="GST NO."
                      />
                    </div>
                    <div class="form-group col-lg-6 col-md-12">
                      <label>PAN NO.</label>
                      <input
                        type="text"
                        v-model="pan_no"
                        placeholder="PAN NO."
                      />
                    </div>
                    <div class="form-group col-lg-6 col-md-12">
                      <label>ESTABLISHED</label>
                      <input
                        type="text"
                        v-model="establisted"
                        placeholder="ESTABLISHED"
                      />
                    </div>
                    <div class="form-group col-lg-6 col-md-12">
                      <label>SPACE REQUIRED</label>
                      <input
                        type="text"
                        v-model="space_required"
                        placeholder="SPACE REQUIRED"
                      />
                    </div>
                    <div class="form-group col-lg-6 col-md-12">
                      <label>NO. OF DISTRIBUTORS</label>
                      <input
                        type="text"
                        v-model="no_of_detributor"
                        placeholder="NO. OF DISTRIBUTORS"
                      />
                    </div>

                    <div class="form-group col-lg-6 col-md-12">
                      <label>COMPANY OUTLETS</label>
                      <input
                        type="text"
                        v-model="company_outlets"
                        placeholder="COMPANY OUTLETS"
                      />
                    </div>
                    <div class="form-group col-lg-6 col-md-12">
                      <label>Business Details</label>
                      <input
                        type="text"
                        v-model="business_details"
                        placeholder="Business Details"
                      />
                    </div>
                    <div class="form-group col-lg-6 col-md-12">
                      <label>Distributorship Level</label>
                      <input
                        type="text"
                        v-model="distributorship_level"
                        placeholder="Distributorship Level"
                      />
                    </div>
                    <div class="form-group col-lg-6 col-md-12">
                      <label>Preferred Location</label>
                      <input
                        type="text"
                        v-model="preferred_location"
                        placeholder="Preferred Location"
                      />
                    </div>
                    <div class="form-group col-lg-6 col-md-12">
                      <label>City</label>
                      <input type="text" v-model="city" placeholder="City" />
                    </div>
                    <div class="form-group col-lg-6 col-md-12">
                      <label>Email</label>
                      <input type="text" v-model="email" placeholder="Email" />
                    </div>
                    <div class="form-group col-lg-6 col-md-12">
                      <label>State</label>
                      <input type="text" v-model="state" placeholder="State" />
                    </div>
                    <div class="form-group col-lg-6 col-md-12">
                      <label>Zip code</label>
                      <input
                        type="text"
                        v-model="zip_code"
                        placeholder="Zip Code"
                      />
                    </div>
                    <div class="form-group col-lg-6 col-md-12">
                      <label>Country</label>
                      <input
                        type="text"
                        v-model="country"
                        placeholder="Country"
                      />
                    </div>

                    <div class="form-group col-lg-6 col-md-12">
                      <label>Category</label>
                      <select v-model="category_id" required>
                        <option disabled value="null">Select a category</option>
                        <option v-for="category in categories" :key="category" :value="category.id">{{ category.name }}</option>
       
                      </select>
                    </div>

                    <div class="form-group col-lg-6 col-md-12">
                      <label>Status</label>
                      <select v-model="status" required>
                        <option disabled value="null">Select a option</option>

                        <option value="1">Enabled</option>
                        <option value="0">Disabled</option>
                      </select>
                    </div>
                    <div class="form-group col-lg-6 col-md-12">
                      <label>Brand</label>
                      <select v-model="brand_id" required>
                        <option disabled value="null">Select a brand</option>

                        <option v-for="brand in brands" :key="brand" :value="brand.id">{{brand.name}}</option>
                       
                      </select>
                    </div>

                    <!-- About Company -->

                    <div class="form-group col-lg-12 col-md-12">
                      <label>Description</label>
                      <textarea
                        v-model="details"
                        placeholder="Description"
                        required
                      ></textarea>
                    </div>

                    <div class="form-group col-lg-6 col-md-12">
                      <label>Logo</label>
                      <input
                       @change="handleLogoUpload"
                        class="form-control"
                        type="file"
                        placeholder="Total program"
                        required
                      />



                      <div class="mt-2" >   

<span
class="mt-2"
v-if="logourl"
style="position: relative"

>
<img
  :src="logourl"
  class="me-3 "
  style="
    height: 80px;
    width: 80px;
    border-radius: 2px;
  "
/>
<i
  style="
    position: absolute;
    right: 10px;
    background-color: rgba(16, 107, 157, 0.933);
    padding: 2px;
    color: #ffffff;
    border-radius: 2px;
  "
  @click="removethum()"
  class="fa-solid fa-x"
></i>
</span>



</div>

                    </div>

                    <!-- Input -->
                    <div class="form-group col-lg-12 col-md-12 text-right">
                      <button
                        @click="addDistributor"
                        class="theme-btn btn-style-one"
                      >
                        Add Distributor
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

// import moment from 'moment';

export default {
  data() {
    return {
      logo: "",
      logourl:"",
      category_id:null,
      brands:[],
      brand_id:null,
      categories:[]
    };
  },
  methods: {
    addDistributor() {
      var formData = new FormData();

      formData.append("name", this.name);
      formData.append("slug", this.slug);
      formData.append("investment_required", this.investment_required);
      formData.append("gst_no", this.gst_no);
      formData.append("established", this.established);
      formData.append("space_required", this.space_required);
      formData.append("no_of_detributor", this.no_of_detributor);
      formData.append("company_outlets", this.company_outlets);
      formData.append("business_details", this.business_details);
      formData.append("preferred_location", this.preferred_location);
      formData.append("distributorship_Level", this.distributorship_Level);
      formData.append("country", this.country);
      formData.append("city", this.city);
      formData.append("email", this.email);
      formData.append("state", this.state);
      formData.append("zip_code", this.zip_code);
      formData.append("category_id", this.category_id);
      formData.append("brand_id", this.brand_id);
      formData.append("logo", this.logo);
      formData.append("details", this.details);
      formData.append("status", this.status);

      axios
        .post("/admin/distributors", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.status == 202) {
            this.$router.push({ name: "admin.distributor" });
            this.$emit("get_message", res.data.message);
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    handleLogoUpload(event) {
      this.logo = event.target.files[0];
      if (this.logo) {
        this.logourl = URL.createObjectURL(this.logo);
      }
    },
    getCategory() {
      axios
        .get("/admin/categories")
        .then((res) => {
          this.categories = res.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getBrand() {
      axios
        .get("/admin/brands")
        .then((res) => {
          this.brands = res.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
  mounted: function () {
    this.getCategory();
    this.getBrand();
  },
};
</script>

