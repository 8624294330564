


const DELIVERY = "Delivery"
const CARRIER = "Carrier"
const COLLATION = "Collation"
const CASH_AND_CARRY = "Cash and carry"

// const filebaseurl="http://localhost/lightspeedback/" 
 const filebaseurl="https://api.lightspeed.pk/" 
    
export default {
  DELIVERY: DELIVERY,
  CARRIER: CARRIER,
  COLLATION: COLLATION,
  CASH_AND_CARRY: CASH_AND_CARRY,
  filebaseurl:filebaseurl
}