<template>
    <section @submit.prevent="postjob" class="user-dashboard">
      <div class="dashboard-outer">
        <div class="upper-title-box">
          <h3>Edit Brand</h3>
        </div>
  
        <div class="row">
          <div class="col-lg-12">
            <!-- Ls widget -->
            <div class="ls-widget">
              <div class="tabs-box p-3">
                <div class="widget-content">
                  <form class="default-form">
                    <div class="row">
                      <!-- Input -->
  
                      <div class="form-group col-lg-12 col-md-12">
                        <label>Brand Name</label>
                        <input
                          type="text"
                          v-model="name"
                          placeholder="Brand Name"
                          required
                        />
                      </div>
  
               
  
                     
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Category</label>
                        <select v-model="category_id" required>
                          <option disabled value="null">Select a category</option>
                          <option v-for="category in categories" :key="category" :value="category.id">{{ category.name }}</option>
         
                        </select>
                      </div>
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Status</label>
                        <select v-model="status" required>
                          <option disabled value="null">Select a option</option>
  
                          <option value="1">Enabled</option>
                          <option value="0">Disabled</option>
                        </select>
                      </div>
  
  
                      <!-- About Company -->
  
                      <div class="form-group col-lg-12 col-md-12">
                        <label>Description</label>
                        <textarea
                          v-model="details"
                          placeholder="Description"
                          required
                        ></textarea>
                      </div>
  
                      <div class="form-group col-lg-6 col-md-12">
                        <label>Logo</label>
                        <input
                          class="form-control"
                          type="file"
                          @change="handleIconUpload"
                          placeholder="Total program"
                          required
                        />
  
                        <div class="mt-2">
                          <span
                            class="mt-2"
                            v-if="iconurl"
                            style="position: relative"
                          >
                            <img
                              :src="iconurl"
                              class="me-3"
                              style="
                                height: 80px;
                                width: 80px;
                                border-radius: 2px;
                              "
                            />
                            <i
                              style="
                                position: absolute;
                                right: 10px;
                                background-color: rgba(16, 107, 157, 0.933);
                                padding: 2px;
                                color: #ffffff;
                                border-radius: 2px;
                              "
                              @click="removethum()"
                              class="fa-solid fa-x"
                            ></i>
                          </span>
                        </div>
                      </div>
  
                      <!-- Input -->
                      <div class="form-group col-lg-12 col-md-12 text-right">
                        <button
                          @click.prevent="addBrand"
                          class="theme-btn btn-sm btn-style-one"
                        >
                          Add Brand
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import axios from "axios";
  
  // import moment from 'moment';
  
  export default {
    data() {
      return {
        icon: "",
        iconurl: "",
        category_id:null,
        categories:[],
        status:null
      };
    },
    methods: {
      addBrand() {
        var formData = new FormData();
  
        formData.append("name", this.name);
        formData.append("parent_id", 1);
        formData.append("category_id", this.category_id);
        formData.append("details", this.details);
        formData.append("status", this.status);
        formData.append("icon", this.icon);
  
        axios
          .post("/admin/brands", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.status == 202) {
              this.$router.push({ name: "admin.brand" });
              this.$emit("get_message", res.data.message);
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      },
  
      getCategory() {
        axios
          .get("/admin/categories")
          .then((res) => {
            this.categories = res.data.data;
          })
          .catch((error) => {
            console.log(error.response);
          });
      },
  
      handleIconUpload(event) {
        this.icon = event.target.files[0];
        if (this.icon) {
          this.iconurl = URL.createObjectURL(this.icon);
        }
      },
    },
    mounted: function () {
  
      this.getCategory();
    },
  };
  </script>
  
  
  
  