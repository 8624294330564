<template>
    <section class="job-detail-section">
      <div style="margin-top: 50px" class="job-detail-outer">
        <div class="auto-container">
          <div class="row">
            <p>Distributors > Global > WheelsHdpe Sheet</p>
  

           
  
            <div class="mt-4" > 
  
              <h3>Search result for "{{ key }}"</h3>
  
              <div class="row mt-3">
          <div
          @click="showdistributor(distributor.id)"
            v-for="distributor in distributors"
            :key="distributor"
            class="col-lg-3"
          >
            <div class="company-block">
              <div class="inner-box">
                <img
                :src="
                    fileurl+'image/distributor/' +
                    distributor.logo
                  " 
                  style="height: 200px"
                  alt=""
                />
                <div class="text-left">
                  <h4 class="text-bold name mt-2">{{ distributor.name }}</h4>

                  <p>
                    Investment price range :
                    {{ distributor.investment_required }}
                  </p>
                  <p>Established : {{ 2020 }}</p>
                  <p>Distributor of : {{ distributor.established }}</p>
                  <!-- <div class="location">
  <i class="flaticon-map-locator"></i> China
</div> -->
                </div>

                <a
                  href="#"
                  style="
                    border-radius: 20px;
                    background-color: rgb(246, 61, 20);
                  "
                  class="btn text-white p-2 w-100 mt-2"
                  >Call Now</a
                >

                <a
                  class="text-bold text-black"
                  style="margin-top: 10px !important"
                  >View datails</a
                >
              </div>
            </div>
          </div>
        </div>
  
  
  
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
    
    <script>
  import axios from "axios";
  import Constant from "@/other/Constant"
  export default {
    data() {
      return {
        product: "",
        distributors:[],
        fileurl: Constant.filebaseurl,
        key:"",

      
      };
    },
  
    methods: {
      showdistributor(id) {
      if (id) {
        this.$router.push({ name: "distributor-single", query: { id: id } });
      }
    },
      getProduct(id) {
        axios
          .get("/public/products/" + id)
          .then((response) => {
            if (response.status == 200) {
              this.product = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      },
      getDistributors(key) {
      axios
        .get("/public/distributors/search/"+key)
        .then((res) => {
          if (res.status == 200) {
            this.distributors = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    },
  
    mounted() {
this.key = this.$route.query.key;
      this.getDistributors(this.key)
    },
  };
  </script>
    
    