<template>
<dashboard-master-view v-if="this.$route.meta.layout==='admin'" />
<master-view  v-else-if="this.$route.meta.layout==null"  />
<auth-master-view  v-else-if="this.$route.meta.layout=='auth'"  />


  
</template>

<script>
import DashboardMasterView from './views/master/DashboardMasterView.vue'
import MasterView from './views/master/MasterView'
import AuthMasterView from './views/master/AuthMasterView'

export default {
   components:{DashboardMasterView,MasterView,AuthMasterView}
}
</script>

<style>

</style>