<template>
  <section class="job-detail-section">
    <div style="margin-top: 50px" class="job-detail-outer">
      <div class="auto-container">
        <div class="row">
          <div class="content-column col-lg-8 col-md-12 col-sm-12">
            <div class="job-block-outer">
              <!-- Job Block -->
              <div class="job-block-seven">
                <div  style="background-color: rgb(255, 248, 244);border-radius: 20px;" class="inner-box p-3">
                  <div class="content">
                    <span v-if="distributor.logo" class="company-logo"
                      ><img :src="
                    fileurl+'image/distributor/' +
                    distributor.logo
                  "  alt=""
                    /></span>
                    <h4 class="text-left">
                      <a  href="#"
                        >{{distributor.name}}</a
                      >
                    </h4>
                    <!-- <ul class="job-info">
                      <li><span class="icon flaticon-briefcase"></span> Segment</li>
                      <li>
                        <span class="icon flaticon-map-locator"></span>
                        {{university.location}},china
                      </li>
                      <li><i class="las la-graduation-cap"></i> {{university.total_student}}</li>

                      <li><i class="las la-graduation-cap"></i> {{ university.international_student }}</li>

                      <li><i class="las la-graduation-cap"></i>{{ university.founded_at}}</li>
                    </ul> -->
                    <ul class="job-other-info">
                      <li class="time">Samsung</li>
                      <li class="privacy">{{ distributor.investment_required }}</li>
                      
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="job-detail">
              <!-- <figure v-if="university.image" class="image">
                <img
                  class="w-100"
                  style="height: 300px"
                  :src="'images/university/image/'+university.image"
                  alt=""
                />
              </figure> -->
              <h4>Business Details</h4>
              <p>
                As a Product Designer, you will work within a Product Delivery
                Team fused with UX, engineering, product and data talent. You
                will help the team design beautiful interfaces that solve
                business challenges for our clients. We work with a number of
                Tier 1 banks on building web-based applications for AML, KYC and
                Sanctions List management workflows. This role is ideal if you
                are looking to segue your career into the FinTech or Big Data
                arenas.
              </p>

              <p>
                As a Product Designer, you will work within a Product Delivery
                Team fused with UX, engineering, product and data talent. You
                will help the team design beautiful interfaces that solve
                business challenges for our clients. We work with a number of
                Tier 1 banks on building web-based applications for AML, KYC and
                Sanctions List management workflows. This role is ideal if you
                are looking to segue your career into the FinTech or Big Data
                arenas.
              </p>

              <h3>Our Products</h3>

              <div class="row">
                <div @click="showProduct(product.id)" v-for="product in products" :key="product" class="col-md-3 ">
            <div style="border-radius: 30px;" class="custom-card p-3 mt-2">
              <div class="row">
                <div class="col-md-12 text-center ">
                  <img class=""
                    style="height:100px; width: 100"
                    :src="
                      fileurl+'image/product/' +
                      product.probduct_thum
                    "
                  />
                </div>

                <div v-if="product.category" class="col-md-12 mt-sm-2">
                         <span  style="font-size: 12px;background-color: #e8ffff ;padding: 2px 20px;border-radius: 15px;color: #53cecb;" class="">
                         {{ product.category.name}}
                         </span>
             
                  <h6
                    class="font110 mt-2"
                    style="text-align: left; font-weight: 500"
                  >
                  {{ product.title}}
                  </h6>

                  
              
                </div>
                
                <div class="row">
                    <div class="col-12">
                      <!-- <span class="icon flaticon-briefcase"></span> -->

                      <span class="small-font ">
                        Investment price range</span
                      >
                    </div>

                    <p class="text-black">1.00Lac-4.00Lac</p>

                  
                  </div>



              </div>
            </div>
          </div>
              </div>

              



      
            </div>
            <!-- Other Options -->
            <div class="other-options">
              <div class="social-share">
                <h5>Share</h5>
                <a href="#" class="facebook"
                  ><i class="fab fa-facebook-f"></i> Facebook</a
                >
                <a href="#" class="twitter"
                  ><i class="fab fa-twitter"></i> Twitter</a
                >
                <a href="#" class="google"
                  ><i class="fab fa-google"></i> Google+</a
                >
              </div>
            </div>

            <!-- <div class="map-outer">
              <div
                class="map-canvas"
                data-zoom="12"
                data-lat="-37.817085"
                data-lng="144.955631"
                data-type="roadmap"
                data-hue="#ffc400"
                data-title="Envato"
                data-icon-path="images/resource/map-marker.png"
                data-content="Melbourne VIC 3000, Australia<br><a href='mailto:info@youremail.com'>info@youremail.com</a>"
              ></div>
            </div> -->

         
          </div>

          <div class="sidebar-column col-lg-4 col-md-12 col-sm-12">
            <aside class="sidebar">
              <div class="btn-box">
                <a href="#" style="background-color: rgb(251, 93, 85);" class="theme-btn btn-sm text-white">SEND YOUR RESPONSE</a>
                <button style="background-color: rgb(255, 248, 244);" class="bookmark-btn">
                  <i class="flaticon-bookmark"></i>
                </button>
              </div>

              <div style="background-color: rgb(255, 248, 244);" class="sidebar-widget">
                <!-- Job Overview -->
                <h4 class="widget-title">Distributor Details</h4>
                <div class="widget-content">
                  <ul class="job-overview">
                    <li>
                      <i class="icon icon-calendar"></i>
                      <h5>MODE</h5>
                      <span>Lokking for distributor</span>
                    </li>
                    <li>
                      <i class="icon icon-expiry"></i>
                      <h5>INVESTMENT REQUIRED</h5>
                      <span>{{ distributor.investment_required }}</span>
                    </li>
                    <li>
                      <i class="icon icon-expiry"></i>
                      <h5>GST NO.</h5>
                      <span>10sdf</span>
                    </li>
                    <li>
                      <i class="icon icon-expiry"></i>
                      <h5>PAN NO</h5>
                      <span>10sdf</span>
                    </li>
                    <li>
                      <i class="icon icon-expiry"></i>
                      <h5>ESTABLISHED</h5>
                      <span>2019</span>
                    </li>
                  </ul>
                </div>

                <!-- Job Skills -->
              </div>

              <div style="background-color: rgb(255, 248, 244);"  class="sidebar-widget contact-widget">
                <h4 class="widget-title">Contact Us</h4>
                <div class="widget-content">
                  <!-- Comment Form -->
                  <div class="default-form">
                    <!--Comment Form-->
                    <form>
                      <div class="row clearfix">
                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                          <input
                            type="text"
                            name="username"
                            placeholder="Your Name"
                            required
                          />
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email Address"
                            required
                          />
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                          <textarea
                            class="darma"
                            name="message"
                            placeholder="Message"
                          ></textarea>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
                          <button
                            class="theme-btn btn-style-one"
                            type="submit"
                            name="submit-form"
                          >
                            Send Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Constant from "@/other/Constant"

export default {

  data() {
    return {
      distributor: "",
      fileurl: Constant.filebaseurl,
      products:[]

      
    };
  },

  methods: {
    getDistributor(id) {
      axios
        .get("/public/distributors/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.distributor = response.data.data;
            this.getProducts();
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getBrandDistributor(id) {
      axios
        .get("/public/distributor/bybrand/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.distributor = response.data.data;
            this.getProducts();
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    getProducts() {
      axios
        .get("/public/products/distributor/" + this.distributor.id)
        .then((res) => {
          if (res.status == 200) {
            this.products = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    showProduct(id) {
      if (id) {
        this.$router.push({ name: "product-single", query: { id: id } });
      }
    },

    

  },

  mounted() {
    var id = this.$route.query.id;

    var bid = this.$route.query.bid;


    if (id != null) {
      this.getDistributor(id);
      window.scrollTo(0, 0);
    }

    if (bid != null) {
      this.getBrandDistributor(bid);
      window.scrollTo(0, 0);
    }
  },
};
</script>

