<template>
  <footer style="background-color: rgb(225, 90, 90);" class="main-footer style-two  ">
  <div class="auto-container">
    <!--Widgets Section-->
    <div class="widgets-section">
      <div class="row">
        <div class="big-column col-xl-4 col-lg-3 col-md-12">
          <div class="footer-column about-widget">
            <h2 class="py-4 text-white" >LIGHTSPEED</h2>
            <p class="phone-num"><span>Call us </span><a href="support@pandaxue.co.cn">+44 7495 587992</a></p>
            <p class="address">128-130 the Grove,China<br><a href="mailto:support@pandaxue.co.uk" class="email">support@lightspeed.co.cn</a></p>
          </div>
        </div>

        <div class="big-column col-xl-8 col-lg-9 col-md-12">
          <div class="row">
           

            <div class="footer-column col-lg-5 col-md-6 col-sm-12">
              <div class="footer-widget links-widget">
                <h4 class="widget-title">Find Important Information</h4>
                <div class="widget-content d-flex">
                  <ul class="list">
                    <li><a href="/">Terms of Conditions</a></li>
                    <li><a href="/">Privacy & Securty</a></li>
                    <li><a href="/">For Student</a></li>
                  </ul>

                  <ul class="list">
                    <li><a href="/">Support</a></li>
                    <li><a href="/">How It Works</a></li>
               
          
                    <li><a href="/">Contact Us</a></li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="footer-column col-lg-6 col-md-12 col-sm-12">
              <div class="footer-widget">
                <h4 class="widget-title">Join Us On</h4>
                <div class="widget-content">
                  <div class="newsletter-form">
                    <div class="text">Please relax; we never send spam.</div>
                    <form method="post" action="#" id="subscribe-form">
                      <div class="form-group">
                        <div class="response"></div>
                      </div>
                      <div class="form-group">
                        <input type="email" name="email" class="email" value="" placeholder="Email" required>
                        <button type="button" id="subscribe-newslatters" class="theme-btn"><i class="flaticon-envelope"></i></button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!--Bottom-->
  <div class="footer-bottom">
    <div class="auto-container">
      <div class="outer-box">
        <div class="copyright-text">© 2023 <a href="#">Lightspeed</a>. All Right Reserved.</div>

        <div class="copyright-text"><strong>Powered by </strong> <a href="/">Lightspeed</a></div>
        <div class="social-links">
          <a href=""><i class="fab fa-facebook-f"></i></a>
          <a href=""><i class="fab fa-twitter"></i></a>
          <!--a href="#"><i class="fab fa-instagram"></i></a-->
          <a href=""><i class="fab fa-linkedin-in"></i></a>
        </div>
      </div>
    </div>
  </div>
  <div class="scroll-to-top scroll-to-target" data-target="html"><span class="fa fa-angle-up"></span></div>
</footer>

</template>


<script>
export default {
name:"FooterView"
}
</script>

<style>

</style>