<template>
  
  <div class="page-wrapper">
    

<!-- Preloader -->
<!-- <div class="preloader"></div -->


  <!-- <div class="loader-large" >


    
  <img style="height: 80px;width: 80px;" src="../../../public/images/resource/favicon.png" >
    <span class="loading__dot_large"></span>
    <span class="loading__dot_large"></span>
    <span class="loading__dot_large"></span>

  </div> -->

<!-- Main Header-->
<header-view></header-view>

<router-view></router-view>
<!--End Main Header -->


<!-- Main Footer -->
<footer-view></footer-view>
<!-- End Main Footer -->

</div><!-- End Page Wrapper -->


  
</template>

<script>

import HeaderView from '@/components/frontend/HeaderView.vue';
import FooterView from '@/components/frontend/FooterView.vue';
export default{
    name:'MasterView',
    components:{HeaderView,FooterView},
     created() {
    var scripts = [

      "frontend/js/script.js",
    

 
    ];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.body.appendChild(tag);
    });
  }
};
</script>

<style>

</style>